// i18next-extract-mark-ns-start page-markets
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import PostLayout from 'components/PostLayout'
import WhatsNext from 'components/Product/WhatsNext'
import UseCases from 'components/Product/UseCases'
import CTA from 'components/Product/CTA'
import Apps from 'components/Product/Apps'
import DataWarehouse from 'components/Product/DataWarehouse'
import * as ProductIcons from 'components/ProductIcons'
import * as NotProductIcons from 'components/NotProductIcons'
import EventPipelines from 'components/Product/EventPipelines'
import { IProps, FeatureWrapperCol } from 'components/Product/FeatureWrapper'
import Hero from 'components/Product/Hero'
import OnPermise from 'components/Product/Complete'
import {
    AppLibrary,
    TaskManagement,
    Dashboards,
    Websites,
    RealestateMarketing,
    MobileApps,
    TransactionManagement,
    Occupancy,
    Maintenance,
    IcloudreadyMonochrome,
    RealestateCrm,
    TopFeatures,
    Accounting,
    DataWarehouse as DataWarehouseIcon,
    API as APIIcon,
    EventPipelines as EventPipelinesIcon,
    OnPermise as OnPermiseIcon,
} from 'components/ProductIcons'
import { GatsbyImage, getImage, ImageDataLike, StaticImage } from 'gatsby-plugin-image'
import React, { useRef, useState } from 'react'
import Scrollspy from 'react-scrollspy'
import { Link } from 'react-scroll'
import Slider from 'react-slick'
import { SEO } from 'components/seo'
import slugify from 'slugify'
import Complete from 'components/Product/Complete'

const features_markets = [
    {
        title: 'Commercial',
        image: <StaticImage src="../components/Home/images/slide-commercial.jpg" alt="Commercial" />,
        disclaimer:
            'Grow your commercial portfolio with the easiest software to manage your retail, office, industrial, shopping centers, parking, & more.',
        cta: { title: 'Learn more', url: '/markets/commercial' },
        icon: <RealestateCrm />,
        subtitle: 'Commercial Property Management Software',
    },
    {
        title: 'Industrial',
        image: <StaticImage src="../components/Home/images/slide-residential.jpg" alt="Industrial" />,
        disclaimer: 'Easily manage your industrial units from anywhere in the world.',
        cta: { title: 'Learn more', url: '/markets/industrial' },
        icon: <RealestateCrm />,
        subtitle: 'Industrial Property Management Software',
    },
    {
        title: 'Residential',
        image: <StaticImage src="../components/Home/images/slide-single-family.jpg" alt="Residential" />,
        disclaimer:
            'Easily manage your residential units from anywhere in the world including single-family, multi-family, condos, apartments, townhouses, duplexes, and much more.',
        cta: { title: 'Learn more', url: '/markets/residential' },
        icon: <RealestateCrm />,
        subtitle: 'Residential Property Management Software',
    },
    {
        title: 'Affordable',
        image: <StaticImage src="../components/Home/images/slide-affordable.jpg" alt="Affordable" />,
        disclaimer: 'Easily manage your affordable units from anywhere in the world.',
        cta: { title: 'Learn more', url: '/markets/affordable' },
        icon: <RealestateCrm />,
        subtitle: 'Affordable Property Management Software',
    },
    {
        title: 'Investment',
        image: <StaticImage src="../components/Home/images/slide-investment.jpg" alt="Investment" />,
        disclaimer:
            'Easily manage your investment units from anywhere in the world including single-family, multi-family, condos, apartments, townhouses, duplexes, and much more.',
        cta: { title: 'Learn more', url: '/markets/investment' },
        icon: <RealestateCrm />,
        subtitle: 'Investment Property Management Software',
    },
    {
        title: 'Student',
        image: <StaticImage src="../components/Home/images/slide-student.jpg" alt="Student" />,
        disclaimer:
            'Easily manage your student units from anywhere in the world including single-family, multi-family, condos, apartments, townhouses, duplexes, and much more.',
        cta: { title: 'Learn more', url: '/markets/student' },
        icon: <RealestateCrm />,
        subtitle: 'Student Property Management Software',
    },
    {
        title: 'Coworking',
        image: <StaticImage src="../components/Home/images/slide-coworking.jpg" alt="Coworking" />,
        disclaimer:
            'Easily manage your coworking units from anywhere in the world including single-family, multi-family, condos, apartments, townhouses, duplexes, and much more.',
        cta: { title: 'Learn more', url: '/markets/coworking' },
        icon: <RealestateCrm />,
        subtitle: 'Coworking Property Management Software',
    },
    {
        title: 'Military',
        image: <StaticImage src="../components/Home/images/slide-military.webp" alt="Military" />,
        disclaimer:
            'Easily manage your military units from anywhere in the world including single-family, multi-family, condos, apartments, townhouses, duplexes, and much more.',
        cta: { title: 'Learn more', url: '/markets/military' },
        icon: <RealestateCrm />,
        subtitle: 'military Property Management Software',
    },
    {
        title: 'Senior',
        image: <StaticImage src="../components/Home/images/slide-senior.jpg" alt="Senior" />,
        disclaimer:
            'Easily manage your senior units from anywhere in the world including single-family, multi-family, condos, apartments, townhouses, duplexes, and much more.',
        cta: { title: 'Learn more', url: '/markets/senior' },
        icon: <RealestateCrm />,
        subtitle: 'Senior Property Management Software',
    },
]

const features_products = [
    {
        title: 'Property management',
        disclaimer: 'Alternative to Amplitude, Heap, Mixpanel',
        image: <StaticImage src="../components/Product/images/property-management.png" alt="Property management" />,
        features: [
            {
                title: 'Occupancy',
                icon: <Occupancy />,
                url: '/product/occupancy',
            },
            {
                title: 'Rental accounting & reporting',
                icon: <Accounting />,
                url: '/product/accounting',
            },
            {
                title: 'Maintenance',
                icon: <Maintenance />,
                url: '/product/property-maintenance',
            },
            {
                title: 'Dashboards',
                icon: <Dashboards />,
                url: '/product/collaboration',
            },
        ],
    },
    {
        title: 'Real estate crm',
        cta: { title: 'Learn more', url: '/product/real-estate-crm' },
        icon: <RealestateCrm />,
        subtitle: 'with console logs',
        image: <StaticImage src="../components/Home/images/slide-commercial.jpg" alt="Real estate crm" />,
        disclaimer: 'Alternative to Hotjar, Logrocket, Matomo',
    },
    {
        title: 'Websites',
        disclaimer: 'Alternative to Optimizely, VWO, Google Optimize',
        image: <StaticImage src="../components/Product/images/websites.png" alt="Websites & websites" />,
        icon: <Websites />,
        features: [
            {
                title: 'Websites Suite',
                icon: <Websites />,
                url: '/real-estate-websites',
            },
            {
                title: 'Task management',
                icon: <TaskManagement />,
                url: '/product/task-management',
            },
        ],
    },
    {
        title: 'Real estate marketing',
        cta: { title: 'Learn more', url: '/product/real-estate-marketing' },
        icon: <RealestateMarketing />,
        subtitle: 'with multivariate testing',
        image: <StaticImage src="../components/Product/images/real-estate-marketing.png" alt="Real estate marketing" />,
        disclaimer: 'Alternative to LaunchDarkly',
    },
    {
        title: 'Mobile Apps',
        cta: { title: 'Learn more', url: '/product/real-estate-mobile-apps' },
        icon: <MobileApps />,
        subtitle: 'with multivariate testing',
        image: <StaticImage src="../components/Product/images/real-estate-mobile-apps.png" alt="Mobile apps" />,
        disclaimer: 'Alternative to LaunchDarkly',
    },
    {
        title: 'Transaction Management',
        cta: { title: 'Learn more', url: '/product/transaction-management' },
        icon: <TransactionManagement />,
        subtitle: 'with multivariate testing',
        image: (
            <StaticImage src="../components/Product/images/transaction-management.png" alt="Transaction management" />
        ),
        disclaimer: 'Alternative to LaunchDarkly',
    },
]

const menu = [
    {
        name: 'Overview',
        url: 'overview',
        icon: <IcloudreadyMonochrome />,
    },
    { name: 'Property type', url: 'property-type', icon: <TopFeatures /> },
    { name: 'Use case', url: 'use-cases', icon: <APIIcon /> },
    // { name: 'Library', url: 'use-cases', icon: <AppLibrary /> },
    // { name: 'ERP integrated', url: 'erp-integrated', icon: <EventPipelinesIcon /> },
    // { name: 'Data warehouse', url: 'data-warehouse', icon: <DataWarehouseIcon /> },
    // { name: 'Cloud or onpremise', url: 'onpremise', icon: <OnPermiseIcon /> },
    // { name: "What's next?", url: 'roadmap', icon: <Websites /> },
]

const Menu = () => {
    return (
        <Scrollspy
            className="m-0 flex list-none flex-col space-y-1 p-0"
            items={menu.map((navItem) => navItem.url)}
            currentClassName="bg-gray-accent-light"
            on
        >
            {menu.map(({ title, url, icon }) => {
                return (
                    <li key={title}>
                        <Link
                            smooth
                            duration={300}
                            to={url}
                            hashSpy
                            className="flex cursor-pointer items-center space-x-2 rounded-md px-3 py-2 text-[17px]  hover:bg-gray-accent-light hover:text-black"
                            spy
                        >
                            <span className="w-[36px]">{icon}</span>
                            <span>{title}</span>
                        </Link>
                    </li>
                )
            })}
        </Scrollspy>
    )
}

const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    variableWidth: true,
}

export default function markets({ data, location, pageContext, children }) {
    const { locales, markets } = data
    const useCasesTransformed = []
    const marketsTransformed = []
    const allMarkets =
        markets?.nodes?.length > 0 &&
        markets.nodes.map((market) => {
            const googleImages = market.parent?.images
            const image = slugify(market.frontmatter?.title || market.fields?.slug, { lower: true })
            // const googleImage = image && googleImages && googleImages.filter((img) => { if (img.name == image) { return img } })[0]
            const googleImage = googleImages && googleImages[0]
            const gatsbyImage = googleImage && getImage(googleImage)
            const Icon = ProductIcons[market.frontmatter?.iconName] || NotProductIcons[market.frontmatter?.iconName]
            let newMarket = {}
            let newUseCase = {}
            if (market.frontmatter?.exclude) {
                return
            }
            if (market.frontmatter?.subtype == 'use-cases') {
                newUseCase = {
                    title: market.frontmatter?.name || market.fields?.slug,
                    image: <GatsbyImage image={gatsbyImage} alt={market.frontmatter?.name || ''} />,
                    disclaimer: market.frontmatter?.subtitle,
                    cta: { title: 'Learn more', url: market.fields?.slug },
                    icon: Icon ? <Icon /> : <></>,
                    subtitle: market.frontmatter?.title,
                }
                useCasesTransformed.push(newUseCase)
                return newUseCase
            } else {
                newMarket = {
                    title: market.frontmatter?.name || market.fields?.slug,
                    image: <GatsbyImage image={gatsbyImage} alt={market.frontmatter?.name || ''} />,
                    disclaimer: market.frontmatter?.subtitle,
                    cta: { title: 'Learn more', url: market.fields?.slug },
                    icon: Icon ? <Icon /> : <></>,
                    subtitle: market.frontmatter?.title,
                }
                marketsTransformed.push(newMarket)
                return newMarket
            }
            // let newSingleElementofRowArray = []
            // for (const key of Object.keys(market)) {
            //     // console.log(`at key ${key}  where values is ${singleElementofRowArray[key]}`)
            //     if (key.endsWith(`_LOAD`)) {
            //         newSingleElementofRowArray.push(market[key])
            //     } else {
            //     }
            // }
            // return newSingleElementofRowArray
        })
    // if (!allMarkets ) {
    //     return <></>
    // }
    // console.log(`markets ${JSON.stringify(markets)}`)
    const sliderRef = useRef()
    const [activeSliderIndex, setActiveSliderIndex] = useState(0)
    return (
        <Layout>
            <SEO title={`markets we serve`} />
            <Hero
                title={`Built for your unique portfolio`}
                title2={`no matter your market or size`}
                description={`Discover Unique Solutions To Outperform Your Market.`}
            />
            <PostLayout
                title="Commercial"
                hideSidebar
                hideSearch
                hideSurvey
                menu={menu}
                article={false}
                contentContainerClassName="w-full md:px-12"
                menuType="scroll"
                menuWidth={{ left: 350, right: 350 }}
                mobileMenu={false}
            >
                <div className="sticky top-0 z-10 mb-8 bg-tan lg:hidden">
                    <Slider ref={sliderRef} {...sliderSettings}>
                        {menu.map(({ name, icon, url }, index) => {
                            return (
                                <div key={name}>
                                    <Link
                                        smooth
                                        duration={300}
                                        offset={-57}
                                        to={url}
                                        hashSpy
                                        className={`hover: mr-1 flex cursor-pointer items-center space-x-2 rounded-md px-3 py-2 text-[14px]  font-semibold hover:bg-gray-accent-light ${
                                            activeSliderIndex === index ? 'bg-gray-accent-light dark:bg-dark' : ''
                                        }`}
                                        spy
                                        onClick={() => sliderRef?.current?.slickGoTo(index)}
                                        onSetActive={() => {
                                            setActiveSliderIndex(index)
                                            sliderRef?.current?.slickGoTo(index)
                                        }}
                                    >
                                        <span className="w-[25px]">{icon}</span>
                                        <span>{name}</span>
                                    </Link>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div id="property-type">
                    <div className="mx-auto box-content max-w-5xl px-5">
                        <h1 className="m-0 text-4xl md:text-5xl">Property Type</h1>
                        <p className="m-0 mt-3 max-w-[700px] text-xl  text-primary dark:!text-light">
                            iCloudReady is the only platform you need for every real estate portfolio.
                        </p>
                    </div>
                    {marketsTransformed?.length > 0 &&
                        marketsTransformed.map((feature, index) => (
                            <FeatureWrapperCol id={slugify(feature.title, { lower: true })} key={index} {...feature} />
                        ))}
                </div>
                {/* <Apps /> */}
                <div id="use-cases">
                    <div className="mx-auto box-content max-w-5xl px-5">
                        <h1 className="m-0 text-4xl md:text-5xl">Use Cases</h1>
                        <p className="m-0 mt-3 max-w-[700px] text-xl font-semibold ">
                            iCloudReady platform is covering the entire uses cases for the entire real estate market.
                        </p>
                    </div>
                    {useCasesTransformed?.length > 0 &&
                        useCasesTransformed.map((feature, index) => (
                            <FeatureWrapperCol id={slugify(feature.title, { lower: true })} key={index} {...feature} />
                        ))}
                </div>
                {/* <UseCases /> */}
                {/* <DataWarehouse /> */}
                {/* <EventPipelines /> */}
                {/* <Complete /> */}
                {/* <WhatsNext /> */}
                <CTA className="md:-mx-12" />
            </PostLayout>
        </Layout>
    )
}

export const query = graphql`
    query MarketsPage($language: String!) {
        locales: allLocale(filter: { language: { eq: $language }, ns: { in: ["page-markets", "common"] } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        markets: allMdx(filter: { fields: { slug: { regex: "/^/markets/(?!.*/docs).*/" } } }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                    logoFileName
                    subtitle
                    name
                    iconName
                    exclude
                    subtype
                    description
                    marketSlides {
                        label
                        url
                        image
                    }
                    marketSliderSettings {
                        dots
                        infinite
                        arrows
                        slidesToScroll
                        autoplay
                        variableWidth
                        autoplaySpeed
                        speed
                        cssEase
                    }
                    showNav
                    showFooter
                    marketTeam
                    marketBlog {
                        title
                    }
                    marketMenuItems
                    marketHero {
                        image {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        width
                        height
                        svgImage
                    }
                    marketPairsWith {
                        title
                        description
                        icon
                        url
                    }
                    marketMainCTA {
                        title
                        subtitle
                        url
                        image {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        svgImage
                    }
                    marketPricingCTA {
                        title
                        url
                    }
                    marketTestimonial {
                        author {
                            name
                            role
                            image
                            companyName
                            companyImage
                            company {
                                name
                                image
                            }
                        }
                        quote
                        image
                        imageFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        svgImage
                    }
                    marketFeatures {
                        title
                        description
                        icon
                    }
                    marketSections {
                        title
                        subtitle
                        content
                        align
                        sections {
                            features {
                                title
                                description
                            }
                        }
                        features {
                            title
                            description
                        }
                        image
                        imageFrame
                        imageMaxWidth
                        imageMaxHeight
                        svgImage
                        imageFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
                parent {
                    ... on GoogleDocs {
                        images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        cover {
                            image {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
