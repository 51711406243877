import { CallToAction } from 'components/CallToAction'
import Logo from 'components/Logo'
import React from 'react'
import { PricingCTA } from 'components/PricingCTA'
import { SignupCTA } from 'components/SignupCTA'

export default function CTA({ className = '' }) {
    return (
        <div className={`bg-blue px-8 pt-12 pb-16 text-white md:px-16 ${className}`}>
            <h4 className="mb-8 text-4xl leading-tight md:mb-4">
                Where else can you get <span className="inline-block text-yellow">end to end</span>{' '}
                <span className="inline-block text-red">property management,</span>{' '}
                <span className="inline-block text-blue">real estate crm,</span>{' '}
                <span className="inline-block text-yellow">real estate marketing,</span>{' '}
                <span className="inline-block text-red">websites, </span>{' '}
                <span className="inline-block text-red">mobile apps,</span>{' '}
                <span className="inline-block text-red">listings hub,</span>{' '}
                <span className="inline-block">a real estate analytics,</span> and{' '}
                <span className="inline-block text-yellow">transaction management...</span> all in one place?
            </h4>
            <div className="mb-8 md:space-x-2">
                <p className="mb-0 inline text-xl font-semibold opacity-70">
                    For all the tools you need with the modern real estate business you want, just use
                </p>
                <span className="inline-flex items-baseline">
                    <span className="relative top-1 mx-1 h-[30px]">
                        <Logo color="white" />
                    </span>
                    {/* <p className="mb-0 text-xl font-semibold opacity-70">.</p> */}
                </span>
            </div>

            <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <SignupCTA className="!w-full md:!w-auto" text="Try iCloudReady Cloud - free" />
                <div className="inline-flex items-center space-x-4">
                    <span>or</span>
                    <PricingCTA dark />
                </div>
            </div>
        </div>
    )
}
