import { CallToAction } from 'components/CallToAction'
import Link from 'components/Link'
import React from 'react'

export interface IProps {
    title: string
    subtitle?: string
    icon?: React.ReactNode
    cta?: {
        title: string
        url: string
    }
    disclaimer?: string
    features?: {
        title: string
        icon: React.ReactNode
        url: string
    }[]
    image: React.ReactNode
    id?: string
}

export const FeatureWrapperCol = ({ title, subtitle, icon, cta, disclaimer, features, image, id }: IProps) => {
    return (
        <div
            className="mx-auto box-content grid max-w-5xl grid-cols-1 items-center space-y-6 border-b border-dashed border-gray-accent-light py-8 px-5 last:border-b-0 md:grid-cols-3 md:space-y-0 md:space-x-16"
            id={id}
        >
            <div>
                {icon && (
                    <span className="mb-3 flex h-[45px] w-[45px] items-center justify-center rounded-md bg-gray-accent-light">
                        <span className="w-5">{icon}</span>
                    </span>
                )}
                <h3 className="m-0 text-2xl">{title}</h3>
                {subtitle && <p className="my-0 mt-1 text-lg  font-semibold">{subtitle}</p>}
                <div className="mt-6">
                    {features && (
                        <ul className="m-0 list-none p-0">
                            {features.map(({ title, icon, url }) => {
                                return (
                                    <li
                                        key={title}
                                        className="border-b border-dashed border-gray-accent-light last:border-b-0"
                                    >
                                        <Link
                                            to={url}
                                            className="group relative my-1 flex items-center space-x-2 rounded py-2 px-1 text-base transition-all hover:bg-gray-accent-light active:top-[1px] active:scale-[.99]"
                                        >
                                            <span className="flex h-[36px] w-[36px] items-center justify-center rounded-sm bg-gray-accent-light group-hover:bg-gray-accent/50">
                                                <span className="w-[20px] text-black">{icon}</span>
                                            </span>
                                            <span>{title}</span>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                    {cta && (
                        <CallToAction size="sm" type="secondary" to={cta.url}>
                            {cta.title}
                        </CallToAction>
                    )}
                    {disclaimer && <p className="m-0  mt-7 text-[15px] italic">{disclaimer}</p>}
                </div>
            </div>
            <div className="col-span-2">{image}</div>
        </div>
    )
}

export const FeatureWrapperRow = ({
    title,
    description,
    children,
    cta,
    id,
}: {
    title: string
    description: string | React.ReactNode
    children?: React.ReactNode
    cta?: {
        url: string
        title: string
    }
    id?: string
}) => {
    return (
        <div className="mt-12 md:mt-28" id={id}>
            <div className="mx-auto box-content max-w-5xl px-5">
                <div className="max-w-[820px]">
                    <h3 className="m-0 text-4xl sm:text-5xl">{title}</h3>
                    <div className="mt-3 font-semibold text-black/75">{description}</div>
                </div>
            </div>
            {children}
            {cta && (
                <div className="mx-auto box-content max-w-5xl px-5">
                    <CallToAction to={cta?.url} type="secondary" size="sm" className={'mt-6'}>
                        {cta?.title}
                    </CallToAction>
                </div>
            )}
        </div>
    )
}
