import { CallToAction } from 'components/CallToAction'
import { SignupCTA } from 'components/SignupCTA'
import React, { useState } from 'react'

interface HeroSultionProps {
    title: string
    title2?: string
    description?: string
    image?: string
    salesandmarketing?: boolean
    propertymanagement?: boolean
}
/*
import Slider from 'react-slick'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'react-scroll'

const slides = [
    {
        label: 'Property management',
        url: 'property-management',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/property-management.png"
            />
        ),
    },
    {
        label: 'Real estate crm',
        url: 'real-estate-crm',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/real-estate-crm.png"
            />
        ),
    },
    {
        label: 'Real estate marketing',
        url: 'real-estate-marketing',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/real-estate-marketing.png"
            />
        ),
    },
    {
        label: 'Mobile Apps',
        url: 'real-estate-mobile-apps',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/real-estate-mobile-apps.png"
            />
        ),
    },
    {
        label: 'Transaction Management',
        url: 'transaction-management',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/transaction-management.png"
            />
        ),
    },
    {
        label: 'Websites and websites',
        url: 'websites-and-websites',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/websites.png"
            />
        ),
    },
    {
        label: 'Data warehouse',
        url: 'data-warehouse',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/sql.png"
            />
        ),
    },
    {
        label: 'Real Estate pipelines',
        url: 'event-pipelines',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/event-pipelines.png"
            />
        ),
    },
    {
        label: 'API',
        url: 'api',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/api.png"
            />
        ),
    },
    {
        label: 'Data warehouse',
        url: 'data-warehouse',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/data-tree.png"
            />
        ),
    },
    {
        label: 'Data warehouse',
        url: 'data-warehouse',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/data-tractor.png"
            />
        ),
    },
    {
        label: 'Data warehouse',
        url: 'data-warehouse',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/data-warehouse.png"
            />
        ),
    },
    {
        label: 'Data warehouse',
        url: 'data-warehouse',
        image: (
            <StaticImage
                placeholder="none"
                loading="eager"
                quality={100}
                objectFit="contain"
                alt=""
                src="./images/readys/warehouse-sync.png"
            />
        ),
    },
]

const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToScroll: 11,
    autoplay: true,
    variableWidth: true,
    autoplaySpeed: 0,
    speed: 100000,
    cssEase: 'linear',
}

const Slide = ({ image, label }) => {
    return (
        <div className="relative group">
            <div className="hover:scale-[1.1] transition-transform relative active:scale-[1.09]">{image}</div>
            <div className="absolute left-0 bottom-4 right-0 group-hover:visible invisible flex justify-center">
                <span className=" bg-tan/75 backdrop-blur shadow-xl text-lg rounded-md px-3">{label}</span>
            </div>
        </div>
    )
}
*/
export default function Hero({
    title,
    title2,
    description,
    image,
    salesandmarketing,
    propertymanagement,
}: HeroSultionProps) {
    //    const [activeSlide, setActiveSlide] = useState(0)
    const defaultTitle = 'The modern platform for'
    const defaultTitle2 = 'Property Management'
    const defaultPropertymanagement = false
    const defaultDescription =
        'iCloudReady is the single platform that can replace an entire suite of tools you’re already paying for.'
    const defaultSalesandmarketing = false
    const defaultImage = ''
    const market = {
        title: title || defaultTitle,
        title2: title2 || defaultTitle2,
        propertymanagement: propertymanagement || defaultPropertymanagement,
        salesandmarketing: salesandmarketing || defaultSalesandmarketing,
        image: image || defaultImage,
        description: description || defaultDescription,
    }
    return (
        <section id="overview" className="mb-8 pt-7 text-center sm:pt-14">
            <div className="relative z-10 mx-auto max-w-screen-2xl px-5">
                <h1 className="m-0 text-3xl sm:text-4xl md:text-6xl">
                    {market.title}
                    <br />
                    <span className="text-red">{market.title2}</span>
                </h1>
                <p className="m-0 mt-3 mb-5 text-lg font-semibold sm:text-xl">{market.description}</p>
                <div className="flex items-center justify-center space-x-3">
                    <SignupCTA />
                    <CallToAction to="/pricing" type="secondary">
                        View pricing
                    </CallToAction>
                </div>
            </div>
            {/* <Slider
                beforeChange={(_oldIndex, newIndex) => setActiveSlide(newIndex)}
                className="product-readys-slider"
                {...sliderSettings}
            >
                {slides.map((slide, index) => {
                    return (
                        <Link key={index} className="cursor-pointer" smooth duration={300} offset={-57} to={slide.url}>
                            <Slide {...slide} />
                        </Link>
                    )
                })}
            </Slider> */}
        </section>
    )
}
